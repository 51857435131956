<div bsModal #messageDetailModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="messageDetailModal" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-body" *ngIf="dialogInfo?.isHtml" [innerHTML]="dialogInfo?.message | safe : securityContext.HTML"></div>
            <div class="modal-body" *ngIf="dialogInfo?.isHtml && dialogInfo?.attempts && dialogInfo.attempts.length > 0">
                <p> {{ 'PreviousAttempts' | localize }}</p>
                <p *ngFor="let attempt of dialogInfo.attempts; let currentIndex=index">
                    {{ l('DisplayNotificationAttemptInfo', currentIndex + 1, attempt.Progress) }}
                </p>
            </div>
            <div class="modal-body" *ngIf="!dialogInfo?.isHtml">
                <div class="row">
                    <div class="col d-flex flex-column align-items-center">
                        <i [ngClass]="severityIcon"></i>
                        <h4 *ngIf="dialogInfo?.title" class="mt-3">
                            {{ dialogInfo?.title }}
                        </h4>
                        <p *ngIf="!dialogInfo?.content" class="mt-3">
                            {{ dialogInfo?.message }}
                        </p>
                        <p *ngIf="dialogInfo?.content" class="mt-3">
                            {{ dialogInfo?.content }}
                        </p>

                        <p-scrollPanel *ngIf="dialogInfo?.listElements?.length" [style]="{ width: '100%', height: '200px' }" class="notificationInfoScrollPanel pt-6">
                            <p *ngFor="let element of dialogInfo.listElements">
                                {{ element }}
                            </p>
                        </p-scrollPanel>
                        <p *ngIf="dialogInfo?.attempts && dialogInfo.attempts.length > 0"> {{ 'PreviousAttempts' | localize }}</p>
                        <p *ngFor="let attempt of dialogInfo?.attempts; let currentIndex=index">
                            {{ l('DisplayNotificationAttemptInfo', currentIndex + 1, attempt.Progress ?? 0) }}
                            <span *ngIf="attempt.Parameters['success']">{{ l('SuccessAttempt', attempt.Parameters['success']) }}</span>
                            <span *ngIf="attempt.Parameters['fail']">{{ l('FailAttempt', attempt.Parameters['fail']) }}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button *ngIf="dialogInfo?.listElements?.length" type="button" class="btn btn-outline-primary fw-bold" [copy]="dialogInfo?.listElements.join('\r\n')">
                    <span>{{ 'Copy' | localize }}</span>
                </button>
                <button type="button" class="btn btn-primary fw-bold" (click)="close()">
                    {{ 'Ok' | localize }}
                </button>
            </div>
        </div>
    </div>
</div>
